.section-about-me {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex-basis: 50%;
}

.frame {
  width: fit-content;
  border: 0.15rem solid var(--main-color);
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  color: var(--main-color);
  background-color: #fff5fa;
  font-weight: 700;
}

.about-img {
  width: 100%;
  border-radius: 4px;
}

/* 768px */
@media only screen and (min-width: 48rem) {
  .section-about-me {
    flex-direction: row;
    gap: 2rem;
  }

  .about-img {
    width: 25rem;
    max-height: 100rem;
  }
}
