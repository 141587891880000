.section-free-consultation {
  display: flex;
  flex-direction: column;
}

.consultation-divide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
}

.consultation-img {
  max-width: 100%;
  border-radius: 4px;
  margin-bottom: 0.75rem;
}

li::marker {
  font-weight: 700;
}

ol {
  padding-inline-start: 20px;
  margin-block: 0;
}

ol ul {
  padding-inline-start: 0;
  list-style-type: none;
}

.center {
  align-self: center;
}

.steps {
  list-style: none;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  margin-block-start: 3rem;
  column-gap: 1rem;
  row-gap: 3rem;
}

.steps li {
  flex-basis: 100%;
  padding: 1rem;
  border: 0.15rem solid var(--main-color-light);
  border-radius: 1rem;
  background-color: #fff7fb;
}

.steps li::before {
  position: absolute;
  margin-top: -3.3rem;
  text-align: center;
  font-size: var(--fs-4);
  font-weight: 700;
  color: var(--main-color);
  width: 83%;
}

.steps li:first-child::before {
  content: '1';
}

.steps li:nth-child(2)::before {
  content: '2';
}

.steps li:nth-child(3)::before {
  content: '3';
}

.steps li:last-child::before {
  content: '4';
}

.steps li:last-child {
  margin-bottom: 1rem;
}

/* 640px */
@media only screen and (min-width: 40rem) {
  .consultation-img {
    margin-bottom: 0.25rem;
  }

  .steps {
    flex-direction: row;
    row-gap: 0;
    margin-bottom: 1rem;
  }

  .steps li::before {
    margin-top: -3.3rem;
    width: 15%;
  }

  .steps li:last-child {
    margin-bottom: 0;
  }
}

/* 768px */
@media only screen and (min-width: 48rem) {
  .consultation-divide {
    flex-direction: column;
    gap: 1rem;
  }
}
