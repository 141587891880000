.section-contact {
  display: flex;
  flex-direction: column;
}

.contact-form {
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.form-group input,
.form-group textarea {
  border-radius: 0.25rem;
  background-color: #fff7fb;
  border: 0.15rem solid #ffb6db;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: var(--main-color-light);
  outline: none;
}

.form-group input {
  height: 2.5rem;
  margin-bottom: 0.75rem;
}

.form-group textarea {
  height: 20rem;
  margin-bottom: 0.4rem;
}

.contact-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
}

.contact-img {
  width: 100%;
  border-radius: 4px;
}

button[type='submit'],
button[type='reset'] {
  background-color: var(--main-color);
  border: 0;
  color: var(--white-color);
  padding: 0.75rem 2rem;
  border-radius: 0.5rem;
  font-size: var(--fs-2);
  font-weight: 500;
  cursor: pointer;
  margin: 0 1rem 0.5rem 0;
}

button[type='submit']:hover,
button[type='reset']:hover {
  background-color: var(--main-color-brighter);
}

.contact-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact-data p {
  font-size: var(--fs-2);
}

.mail-container {
  font-weight: 700;
  color: var(--main-color);
}

.mail-container:hover {
  color: var(--main-color-brighter);
}

.message-container {
  color: var(--main-color);
  font-weight: 500;
}

.permission {
  font-size: var(--fs-1);
  margin-bottom: 1rem;
}

.error {
  color: #ff0050;
  font-weight: 500;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: var(--white-color);
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  border: 0.25rem solid var(--main-color-light);
}

.modal button {
  margin-top: 1rem;
  padding: 0.85rem 2rem;
  border: none;
  background-color: var(--main-color);
  color: var(--white-color);
  border-radius: 5px;
  cursor: pointer;
}

.modal h2 {
  padding-bottom: 1rem;
}

.modal button:hover {
  background-color: var(--main-color-brighter);
}


/* 640px */
@media only screen and (min-width: 40rem) {
  .contact-info {
    align-items: center;
    gap: 1rem;
  }

  .contact-form {
    min-width: 13rem;
    max-width: 45%;
  }

  .contact-img {
    min-width: 18rem;
    width: 46%;
  }
}

/* 768px */
@media only screen and (min-width: 48rem) {
  .contact-info {
    gap: 2rem;
  }

  .contact-img {
    width: 21rem;
  }

  .contact-data p {
    font-size: var(--fs-3);
  }
}

/* 1024px */
@media only screen and (min-width: 64rem) {
  .contact-img {
    width: 26rem;
  }
}

/* 1280px */
@media only screen and (min-width: 80rem) {
  .contact-img {
    max-width: 35rem;
  }
}
