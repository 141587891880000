.section-full-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffefef;
  padding-block-start: 1rem;
  padding-block-end: 2rem;
}

.text-center {
  text-align: center;
}

.person-info {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
}

.person-img {
  border: 0.3rem solid var(--main-color);
  border-radius: 50%;
  width: 15rem;
}

.speech-bubble-container {
  display: flex;
  flex-direction: row;
  row-gap: 2rem;
  column-gap: 1rem;
}

.top {
  flex-direction: column;
}

.bottom {
  flex-direction: column;
}

.speech-bubble {
  position: relative;
  background: var(--main-color);
  border-radius: 1.5rem;
  color: var(--white-color);
  padding: 1rem;
  height: fit-content;
  margin: 0 0.5rem;
}

.speech-bubble p {
  shape-outside: circle(50%);
  border-radius: 50%;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 1.188rem solid transparent;
}

.top .speech-bubble:after {
  bottom: -3rem;
  left: 50%;
  border-top: 1.75rem solid var(--main-color);
  border-right: 1.75rem solid transparent;
  border-bottom: 1.75rem solid transparent;
  border-left: 1.75rem solid transparent;
}

.bottom .speech-bubble:after {
  top: -3rem;
  left: 50%;
  border-top: 1.75rem solid transparent;
  border-right: 1.75rem solid transparent;
  border-bottom: 1.75rem solid var(--main-color);
  border-left: 1.75rem solid transparent;
}

.speech-bubble ul {
  padding-inline-start: 1.25rem;
}

/* 640px */
@media only screen and (min-width: 40rem) {
  .section-full-container {
    align-items: flex-start;
  }

  .top {
    flex-direction: row;
  }
  .speech-bubble {
    margin: 0;
  }

  .speech-bubble ul {
    padding-inline-start: 2rem;
  }
}
