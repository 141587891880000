.section-image {
  display: flex;
  justify-content: center;
  background-color: var(--white-color);
  background-image: url('/public/img/agnieszka-baranowska-przystan-zmiany-zawodowej-mentorka-psycholog.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 90vh;
  padding-top: 2rem;
  padding-bottom: 7.5rem;
}

.section-image-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.image-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  max-width: 18rem;
  text-align: center;
}

.image-button-social {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.btn-round {
  background-color: var(--main-color);
  color: var(--white-color);
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  cursor: pointer;
  font-size: var(--fs-4);
  font-weight: bold;
  transition: background-color 0.2s ease;
  width: 90%;
  align-self: center;
  max-width: 17rem;
  line-height: 2rem;
}

.icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.icon {
  background-color: var(--main-color);
  border-radius: 50%;
  width: 3rem;
  padding: 0.2rem;
  transition: background-color 0.2s ease;
}

.btn-round:hover,
.icon:hover {
  background-color: var(--main-color-brighter);
}

/* 640px */
@media only screen and (min-width: 40rem) {
  .section-image {
    padding-top: 3rem;
    padding-bottom: 2.5rem;
  }

  .btn-round {
    max-width: 24rem;
  }

  .image-text {
    width: 30rem;
    max-width: none;
  }

  .image-button-social {
    gap: 2rem;
  }
}

/* 768px */
@media only screen and (min-width: 48rem) {
  .section-image-container {
    justify-content: flex-end;
    gap: 2rem;
  }

  .btn-round {
    width: max-content;
    max-width: none;
    padding: 1rem 1.5rem;
  }
}

/* 1024px */
@media only screen and (min-width: 64rem) {
  .image-text {
    width: 70rem;
  }
}

/* 1280px */
@media only screen and (min-width: 80rem) {
  .section-image {
    background-position-y: 46%;
  }
}
